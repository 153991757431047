import React, { useState, useEffect } from 'react';   
import { db, auth } from '../firebase';
import { collection, addDoc, doc as firdoc, getDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../style/OrderSample.css';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { onAuthStateChanged } from 'firebase/auth';
import Modal from '../components/Modal';
import { jsPDF } from 'jspdf'; // Import jsPDF for PDF generation
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'; // Firebase Storage imports
import { Storage } from '../firebase'; // Assuming you have your Firebase storage initialized

// Modal Component
const ConfirmationModal = ({ totalPrice, onConfirm, onCancel }) => {
    return (
        <div className="modals">
            <div className="modal-contents">
            <h2>Confirm Order</h2>
            <p>Are you sure you want to place this order? Total Price: ₹{totalPrice}</p>
            <button onClick={onConfirm}>Confirm</button>
            <button onClick={onCancel} className='cancel'>Cancel</button>
            </div>
        </div>
    );
};

const SampleOrder = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const shopId = queryParams.get('shop'); 
    const totalPrice = parseFloat(queryParams.get('totalPrice'));
    const designName = queryParams.get('designName');
    const designId = queryParams.get('design'); 

    const [userId, setUserId] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('Maharashtra');
    const [pinCode, setPinCode] = useState('');
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [walletBalance, setWalletBalance] = useState(0);
    const [showModal, setShowModal] = useState(false); // Confirmation modal visibility state
    const [showSuccessModal, setShowSuccessModal] = useState(false); // Success modal visibility state
    const navigate = useNavigate();
    
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserId(user.uid);
                setEmail(user.email); // Optionally set the email
            } else {
                toast.error('User not authenticated.');
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchWalletBalance = async () => {
            if (!userId) return;

            try {
                const walletRef = firdoc(db, 'users', userId, 'wallet', 'walletDetails');
                const walletSnap = await getDoc(walletRef);
                if (walletSnap.exists()) {
                    const walletData = walletSnap.data();
                    setWalletBalance(walletData.balance);
                } else {
                    toast.error('Wallet not found. Please recharge.');
                }
            } catch (error) {
                console.error('Error fetching wallet balance:', error);
                toast.error('Error fetching wallet balance.');
            }
        };

        fetchWalletBalance();
    }, [userId]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (walletBalance < totalPrice) {
            toast.error('Insufficient balance. Please recharge your wallet.');
            return;
        }
        setShowModal(true); // Show the confirmation modal
    };

    const handleConfirm = async () => {
        setLoading(true);
        setShowModal(false); // Hide the confirmation modal
    
        const orderData = {
            customer: {
                name,
                email,
                address,
                city,
                state,
                pinCode,
                phone,
            },
            product: designName,
            designId, 
            createdAt: Timestamp.now(),
            status: 'pending',
            isPaid: false,
            totalPrice: totalPrice,
            shopId, // Include shopId in the order data
        };
    
        try {
            // Call the API to create the sample order
            const response = await axios.post('https://ufb-1.onrender.com/create-sampleshiprocket-order', {
                ...orderData,
                shopId, // Ensure shopId is passed as part of the request payload
            });
    
            const { data } = response;
            if (data && data.channel_order_id) {
                // Save orderId and other details to Firestore
                const SampleordersCollection = collection(db, 'shops', shopId, 'sampleorders');
                const docRef = await addDoc(SampleordersCollection, {
                    ...orderData,
                    orderId: data.channel_order_id, // Store Shiprocket order ID
                });
    
                // Generate and Upload Invoice PDF
                const doc = new jsPDF();
                const invoiceName = `sample_${designName.replace(/\s+/g, '_').toLowerCase()}_${Date.now()}.pdf`; // Dynamic invoice name
    
                // Header Section
                doc.setFontSize(20);
                doc.text('TAX INVOICE', 150, 15, null, null, 'center');
    
                // Company Information
                doc.setFontSize(10);
                doc.setFont("Helvetica", "normal");
                doc.text("UNITEE STUDIOS PRIVATE LIMITED", 10, 20);
                doc.text("Maharashtra, India", 10, 25);
                doc.text("GSTIN 27AADCU3575G1Z5", 10, 30);
                doc.text("rajprit@uniteeculture.com", 10, 35);
                doc.text("1ST FLOOR, PLOT 304/E/, 3, WAKHARIA BUILDING Vithalbhai Patel Road", 10, 40);
                doc.text("MUMBAI", 10, 45);
    
                // Invoice Details
                const invoiceDate = new Date().toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: '2-digit' });
                doc.text("Invoice Details", 150, 25, null, null, 'center');
                doc.text(`Invoice Date: ${invoiceDate}`, 140, 30);
                doc.text(`Invoice Number: ${invoiceName}`, 140, 35); // Using dynamic name
    
                // Customer Details
                doc.text("Bill To", 10, 60);
                doc.text(`Name: ${name}`, 10, 65);
                doc.text(`Address: ${address}`, 10, 70);
                doc.text(`City: ${city}`, 10, 75);
                doc.text(`State: ${state}`, 10, 80);
                doc.text(`Pin Code: ${pinCode}`, 10, 85);
                doc.text(`GST Number: ${''}`, 10, 90); // Assuming GST is not available
    
                // Add Table for Products
                doc.text('Product Details', 10, 100);
                const finalAmountY = doc.autoTable.previous.finalY + 10;
                doc.autoTable({
                    startY: 105,
                    head: [['Product Name', 'Quantity', 'Price', 'Total']],
                    body: [
                        [designName, '1', `₹${totalPrice.toFixed(2)}`, `₹${totalPrice.toFixed(2)}`],
                    ],
                    theme: 'grid',
                    styles: { fontSize: 10 },
                });
    
                // Calculate Tax (5%)
                const tax = totalPrice * 0.05; // 5% tax
                const totalWithTax = totalPrice + tax;
    
                // Tax Details
                doc.text(`5% Tax: Rs.${tax.toFixed(2)}`, 10, doc.lastAutoTable.finalY + 10);
                doc.text(`Total Amount (Including Tax): Rs.${totalWithTax.toFixed(2)}`, 10, doc.lastAutoTable.finalY + 15);
                  

                  // Footer Notes
            doc.text("Thanks for your business.", 10, finalAmountY + 25);
            doc.text("Authorized Signature", 150, finalAmountY + 25);

                // Upload Invoice to Firebase Storage
                const invoiceRef = ref(Storage, `invoices/${invoiceName}`);
                const pdfBlob = doc.output('blob');
                const uploadTask = uploadBytesResumable(invoiceRef, pdfBlob);
    
                uploadTask.on('state_changed', null, (error) => {
                    console.error('Error uploading invoice:', error);
                    toast.error('Error uploading invoice');
                }, async () => {
                    // Correct access to the snapshot ref
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref); // Using snapshot.ref directly
    
                    console.log('Invoice uploaded, download URL:', downloadURL);
    
                    // Update Firestore with Invoice URL
                    const orderRef = firdoc(db, 'shops', shopId, 'sampleorders', docRef.id);
                    await updateDoc(orderRef, { invoiceUrl: downloadURL });
    
                    // Update wallet balance
                    const updatedBalance = walletBalance - totalPrice;
                    const walletRef = firdoc(db, 'users', userId, 'wallet', 'walletDetails');
                    await updateDoc(walletRef, { balance: updatedBalance });
    
                    toast.success('Sample order created successfully with invoice!');
                    setShowSuccessModal(true); // Show success modal after order is placed
                });
            } else {
                toast.error('Failed to create the sample order on Shiprocket.');
            }
        } catch (error) {
            console.error('Error creating order:', error);
            toast.error('Error creating order.');
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setShowModal(false); // Close the confirmation modal without proceeding
    };

    const handleGoToDashboard = () => {
        navigate(`/dashboard?shop=${encodeURIComponent(shopId)}`);
    };

    return (
        <>
            <Header />
            <div className="products-container">
                <Sidebar />
                <div className="sample-order">
                    <h2>Create Sample Order</h2>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label>Name:</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>Email:</label>
                            <input
                                type="email"
                                value={email}
                                readOnly 
                            />
                        </div>
                        <div>
                            <label>Address:</label>
                            <input
                                type="text"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>City:</label>
                            <input
                                type="text"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>PIN Code:</label>
                            <input
                                type="text"
                                value={pinCode}
                                onChange={(e) => setPinCode(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>Phone Number:</label>
                            <input
                                type="tel"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" disabled={loading}>
                            {loading ? 'Submitting...' : 'Submit Order'}
                        </button>
                    </form>

                    {showModal && (
                        <ConfirmationModal 
                            totalPrice={totalPrice} 
                            onConfirm={handleConfirm} 
                            onCancel={handleCancel} 
                        />
                    )}

                    {showSuccessModal && (
                        <Modal>
                            <div className="modal-content">
                                <h2>Your sample order has been created successfully!</h2>
                                <button onClick={handleGoToDashboard}>Go to Dashboard page!</button>
                            </div>
                        </Modal>
                    )}

                    <ToastContainer />
                </div>
            </div>
        </>
    );
};

export default SampleOrder;

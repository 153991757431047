import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import '../style/products.css';

const BookSample = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shopId = queryParams.get('shop');

  const [designs, setDesigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const user = auth.currentUser;
  
  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const designsPerPage = 7;

  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    if (!user) {
      // If user is not logged in, show error and redirect to login
      setError('User not logged in. Please log in.');
      navigate('/login'); // Redirect to login page
    } else {
      // User is logged in, fetch data
      const fetchDesigns = async () => {
        try {
          if (!shopId) {
            throw new Error("Shop ID is missing");
          }

          const designsCollection = collection(db, 'shops', shopId, 'designs');
          const designSnapshot = await getDocs(designsCollection);
          const designList = designSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setDesigns(designList);
        } catch (error) {
          console.error("Error fetching designs:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchDesigns();
    }
  }, [user, shopId, navigate]);

  // Calculate pagination
  const totalPages = Math.ceil(designs.length / designsPerPage);
  const indexOfLastDesign = currentPage * designsPerPage;
  const indexOfFirstDesign = indexOfLastDesign - designsPerPage;
  const currentDesigns = designs.slice(indexOfFirstDesign, indexOfLastDesign);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleBookSample = (designId,  designName, totalPrice) => {

    const updatedTotalPrice = totalPrice + 100 + ((totalPrice + 100)* 0.05);
    // Redirect to the sample order page for the specific design
    navigate(`/order-sample?shop=${shopId}&design=${designId}&designName=${designName}&totalPrice=${updatedTotalPrice}`);
  };

  if (loading) {
    return <div className="loading-message">Loading...</div>;
  }

  return (
    <div className="products-page">
      <Header />
      <div className="products-container">
        <Sidebar />
        <div className="main-content">
          <Link to={`/selecttshirt?shop=${shopId}`} className="create-button">Create Product</Link>
          {designs.length === 0 ? (
            <p>No designs uploaded yet for this shop.</p>
          ) : (
            <>
              <table className="design-table">
                <thead>
                  <tr>
                    <th>Design Name</th>
                    <th>Product</th>
                    <th>Front Image</th>
                    <th>Back Image</th>
                    <th>Total Price</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentDesigns.map(design => (
                    <tr key={design.id}>
                      <td>{design.designName}</td>
                      <td>{design.productName}</td>
                      <td><img src={design.images.front} alt="Front Design" width="50" /></td>
                      <td><img src={design.images.back} alt="Back Design" width="50" /></td>
                      <td>{design.totalPrice}</td>
                      <td>
                        <button onClick={() => handleBookSample(design.id,design.designName,design.totalPrice)}>
                          Book a Sample
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index + 1}
                    onClick={() => handlePageChange(index + 1)}
                    className={currentPage === index + 1 ? 'active' : ''}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookSample;




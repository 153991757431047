import React, { useState, useEffect } from "react";
import "../style/Sidebar.css";
import {
  FaTimes,
  FaBars,
  FaChartLine,
  FaShapes,
  FaTshirt,
  FaRocket,
  FaShoppingCart,
  FaCheckCircle,
  FaMoneyCheckAlt,
  FaFileContract,
  FaLock,
  FaUndo,
  FaShippingFast,
  FaEnvelope,
  FaPhoneAlt,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFounderOpen, setIsFounderOpen] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shop = queryParams.get("shop");

  // State to track if it's desktop or mobile
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  // Adjust layout on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleFounderInfo = () => {
    setIsFounderOpen(!isFounderOpen);
  };

  return (
    <>
      {/* Hamburger Menu (Mobile Only) */}
      {!isDesktop && (
        <div className="hamburger-menu" onClick={toggleSidebar}>
          <FaBars />
        </div>
      )}

      {/* Sidebar */}
      <div className={`sidebar ${isSidebarOpen || isDesktop ? "open" : "closed"}`}>
        {/* Close Button (Mobile Only) */}
        <div className="sidebar-header">
          <div className="logo">
            <Link to={`/dashboard?shop=${encodeURIComponent(shop)}`}>
              <img src="logo.png" alt="Logo" />
            </Link>
          </div>
          {!isDesktop && (
            <div className="close-icon" onClick={toggleSidebar}>
              <FaTimes />
            </div>
          )}
        </div>

        {/* Menu Items */}
        <div className="menu">
          <div className="menu-item">
            <Link to={`/dashboard?shop=${encodeURIComponent(shop)}`}>
              <FaChartLine className="icon" />
              <span>Dashboard</span>
            </Link>
          </div>
          <div className="menu-item">
            <Link to={`/Products?shop=${encodeURIComponent(shop)}`}>
              <FaShapes className="icon" />
              <span>Products</span>
            </Link>
          </div>
          <div className="menu-item">
            <Link to={`/Book-a-sample?shop=${encodeURIComponent(shop)}`}>
              <FaTshirt className="icon" />
              <span>Book a Sample</span>
            </Link>
          </div>
          <div className="menu-item">
            <Link to={`/SelectTshirt?shop=${encodeURIComponent(shop)}`}>
              <FaRocket className="icon" />
              <span>Create Product</span>
            </Link>
          </div>
          <div className="menu-item">
            <Link to={`/OrdersPage?shop=${encodeURIComponent(shop)}`}>
              <FaShoppingCart className="icon" />
              <span>Orders</span>
            </Link>
          </div>
          <div className="menu-item">
            <Link to={`/PaidOrders?shop=${encodeURIComponent(shop)}`}>
              <FaCheckCircle className="icon" />
              <span>Confirmed Orders</span>
            </Link>
          </div>
          <div className="menu-item">
            <Link to={`/Transaction?shop=${encodeURIComponent(shop)}`}>
              <FaMoneyCheckAlt className="icon" />
              <span>Transactions</span>
            </Link>
          </div>
          <div className="menu-item">
            <Link to={`/privacy-policy?shop=${encodeURIComponent(shop)}`}>
              <FaLock className="icon" />
              <span>Privacy Policy</span>
            </Link>
          </div>
           <div className="menu-item">
            <Link to={`/terms-and-conditions?shop=${encodeURIComponent(shop)}`}>
              <FaFileContract className="icon" />
              <span>Terms and Conditions</span>
            </Link>
          </div>
          {/*
          <div className="menu-item">
            <Link to={`/cancellation-policy?shop=${encodeURIComponent(shop)}`}>
              <FaUndo className="icon" />
              <span>Cancellation and Refund</span>
            </Link>
          </div>
          <div className="menu-item">
            <Link to={`/shipping-policy?shop=${encodeURIComponent(shop)}`}>
              <FaShippingFast className="icon" />
              <span>Shipping and Delivery</span>
            </Link>
          </div> */}
          <div className="menu-item">
            <Link to={`/contact-us?shop=${encodeURIComponent(shop)}`}>
              <FaEnvelope className="icon" />
              <span>Contact Us</span>
            </Link>
          </div>
          <div className="menu-item">
            <div onClick={toggleFounderInfo} className="founder-link">
              <FaPhoneAlt className="icon" />
              <span>Call a Founder</span>
            </div>
            {isFounderOpen && (
              <div className="founder-info">
                <p>Name: Rajprit Sahni</p>
                <p>Phone: +91 81699 32616</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase'; // Ensure you're importing auth if needed
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useLocation } from 'react-router-dom'; // Use useLocation for query parameters
import '../style/OrdersPage.css';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

const PaidOrdersPage = () => {
    const [paidOrders, setPaidOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation(); // Get the current location
    const queryParams = new URLSearchParams(location.search);
    const shopId = queryParams.get('shop'); // Retrieve shopId from query parameters

    const fetchPaidOrders = async () => {
        if (!shopId) {
            console.error("Shop ID is missing");
            setLoading(false);
            return;
        }

        try {
            const ordersCollection = collection(db, 'shops', shopId, 'orders');
            const q = query(ordersCollection, where('isPaid', '==', true));
            const ordersSnapshot = await getDocs(q);
            const ordersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setPaidOrders(ordersList);
        } catch (error) {
            console.error("Error fetching paid orders:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPaidOrders();
    }, [shopId]);

    const handleDownloadInvoice = (invoiceUrl) => {
        if (invoiceUrl) {
            window.open(invoiceUrl, '_blank');
        } else {
            alert('Invoice not available for this order.');
        }
    };

    if (loading) {
        return <div>Loading paid orders...</div>;
    }

    // if (paidOrders.length === 0) {
    //     return <div>No paid orders found.</div>;
    // }

    return (
        <div className="orders-page">
            <Header />
            <div className="orders-container">
                <Sidebar />
                <div className="main-content">
                    <h1>Paid Orders</h1>
                    <table className="orders-table">
                        <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>SKU</th>
                                <th>Quantity</th>
                                <th>Invoice</th> {/* New column for Invoice */}
                            </tr>
                        </thead>
                        <tbody>
                            {paidOrders.map((order) => (
                                <tr key={order.id}>
                                    <td>{order.id}</td>
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.sku}</p>
                                        ))}
                                    </td>
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.units}</p>
                                        ))}
                                    </td>
                                    <td>
                                        {/* Check if invoice URL exists and add the button */}
                                        {order.invoiceUrl ? (
                                            <button 
                                                onClick={() => handleDownloadInvoice(order.invoiceUrl)} 
                                                className="download-invoice-btn"
                                            >
                                                Download Invoice
                                            </button>
                                        ) : (
                                            <span>No Invoice</span>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default PaidOrdersPage;



// import React, { useEffect, useState } from 'react';
// import { db, auth } from '../firebase'; // Ensure you're importing auth if needed
// import { collection, getDocs, query, where } from 'firebase/firestore';
// import { useLocation } from 'react-router-dom'; // Use useLocation for query parameters
// import '../style/OrdersPage.css';
// import Sidebar from '../components/Sidebar';
// import Header from '../components/Header';

// const PaidOrdersPage = () => {
//     const [paidOrders, setPaidOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const location = useLocation(); // Get the current location
//     const queryParams = new URLSearchParams(location.search);
//     const shopId = queryParams.get('shop'); // Retrieve shopId from query parameters

//     const fetchPaidOrders = async () => {
//         if (!shopId) {
//             console.error("Shop ID is missing");
//             setLoading(false);
//             return;
//         }

//         try {
//             const ordersCollection = collection(db, 'shops', shopId, 'orders');
//             const q = query(ordersCollection, where('isPaid', '==', true));
//             const ordersSnapshot = await getDocs(q);
//             const ordersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//             setPaidOrders(ordersList);
//         } catch (error) {
//             console.error("Error fetching paid orders:", error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchPaidOrders();
//     }, [shopId]);

//     if (loading) {
//         return <div>Loading paid orders...</div>;
//     }

//     // if (paidOrders.length === 0) {
//     //     return <div>No paid orders found.</div>;
//     // }

//     return (
//         <div className="orders-page">
//             <Header />
//             <div className="orders-container">
//                 <Sidebar />
//                 <div className="main-content">
//                     <h1>Paid Orders</h1>
//                     <table className="orders-table">
//                         <thead>
//                             <tr>
//                                 <th>Order ID</th>
//                                 {/* <th>Design Name</th> */}
//                                 <th>SKU</th>
//                                 <th>Quantity</th>
//                                 {/* <th>Total Cost</th> */}
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {paidOrders.map((order) => (
//                                 <tr key={order.id}>
//                                     <td>{order.id}</td>
//                                     {/* <td>
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>{item.design?.designName || 'Unknown'}</p>
//                                         ))}
//                                     </td> */}
//                                     <td>
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>{item.sku}</p>
//                                         ))}
//                                     </td>
//                                     <td>
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>{item.units}</p>
//                                         ))}
//                                     </td>
//                                     {/* <td>
//                                         {order.order_items.reduce((sum, item) => sum + (item.design?.totalPrice || 0) * item.units, 0)}
//                                     </td> */}
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default PaidOrdersPage;